<template>
  <div class="wrap">
    <div>
      <div class="bread">
        <img class="add" src="@/assets/address.png" />
        <span>当前位置：</span>
        <router-link to="/">首页</router-link>
        <img class="right" src="@/assets/breadr.png" />
        <router-link to="/media">媒体聚焦</router-link>
        <img class="right" src="@/assets/breadr.png" />
        <router-link v-if="$route.name === 'SpzxDetail'" to="/media/spzx"
          >视频中心</router-link
        >
        <router-link v-else to="/media/wccp">文创产品</router-link>
        <img class="right" src="@/assets/breadr.png" />
        <router-link
          :to="{ name: $route.title, params: { id: $route.params.id } }"
          >详情</router-link
        >
      </div>
    </div>
    <div class="detail-wrap">
      <h1>{{ info.title }}</h1>
      <video :src="info.video" controls autoplay></video>
    </div>
  </div>
</template>

<script>
export default {
  name: "MediaDetail",
  data() {
    return {
      info: {},
    };
  },
  created() {
    this.info = this.$route.params;
  },
};
</script>

<style lang="less" scoped>
@media screen and (min-width: 750px) {
  .wrap {
    background: #f2f2f2;
    > .detail-wrap {
      background: #fff;
      padding: 70px 100px;
      > h1 {
        font-size: 28px;
        font-weight: bold;
        color: #333333;
        line-height: 40px;
        text-align: center;
        font-family: PingFangSC-Medium, PingFang SC;
      }
      > video {
        margin-top: 30px;
        width: 100%;
        height: 890px;
      }
    }
  }
}
@media screen and (max-width: 750px) {
  .wrap {
    background: #f2f2f2;
    > .detail-wrap {
      background: #fff;
      padding: 16px 15px;
      > h1 {
        font-size: 18px;
        font-weight: bold;
        color: #333333;
        line-height: 25px;
        text-align: center;
        font-family: PingFangSC-Medium, PingFang SC;
      }
      > video {
        margin-top: 12px;
        width: 100%;
      }
    }
  }
}
</style>